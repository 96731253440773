<template>
  <div id="navbarHeader" class="web-navbar">
        <div class="web-center flex-between">
            <div class="navbar-left">
              <router-link to="/" class="logo-link">
                    <img src="@/assets/img/ceptasgrup.png" alt="">
              </router-link>
            </div>
            <div class="hamburger-menu">
                <span @click="hamburgerMenu()"><i class="fa-solid fa-bars"></i></span>
            </div>
            <div style="left: -1000px;" id="hamburgerMenuContainer" class="navbar-right">
                <div class="navbar-menu-bottom">
                    <ul>
                      <router-link to="/hakkimizda" @click="closeMenu()">Hakkımızda</router-link>
                      <router-link to="/faaliyetalanlari" @click="closeMenu()">Faaliyet Alanlarımız</router-link>
                      <router-link to="/sirketlerimiz" @click="closeMenu()">Şirketlerimiz</router-link>
                      <router-link to="/iletisim" @click="closeMenu()">İletişim</router-link>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
  name: 'Header',
  methods: {
    hamburgerMenu() {
        var x = document.getElementById('hamburgerMenuContainer');
        if (x.style.left === '-1000px') {
            x.style.left = '0px';
            console.log(x.style.left);
        }
        else {
            x.style.left = '-1000px';
            console.log(x.style.left);
        }
    },
    closeMenu(){
      var x = document.getElementById('hamburgerMenuContainer');
      x.style.left = '-1000px'
    }
  }
}

</script>

<style scoped>
</style>
  