<template>
    <div class="page-header">
        <div class="web-center">
            <h1>{{title}}</h1>
        </div>
    </div>
</template>

<script>

export default {
  name: 'PageTitle',
  props: {
    title: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
