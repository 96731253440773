<template>

  <div id="app">
    <Header/>
    <router-view v-slot="{ Component, route }">
      <transition name="fade" mode="out-in">
        <div :key="route.name">  
          <component :is="Component"></component>
        </div>
      </transition>
    </router-view>
    <Footer/>
  </div>

</template>

<script>
import Header from '@/components/Header.vue'
import Anasayfa from '@/views/Anasayfa.vue'
import Hakkimizda from '@/views/Hakkimizda.vue'
import FaaliyetAlanlari from '@/views/FaaliyetAlanlari.vue'
import Sirketlerimiz from '@/views/Sirketlerimiz.vue'
import Contact from '@/views/Contact.vue'
import Footer from '@/components/Footer.vue'

export default{
  name: 'app',
  components: {
    Header,Anasayfa,Hakkimizda,FaaliyetAlanlari,Sirketlerimiz,Contact,Footer
  }
}

</script>


<style>
/* .fade-enter-active, .fade-leave-active {
  transition: opacity 200ms;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
} */
@import url(../src/assets/css/style.css);
</style>
