<template>
    <PageTitle title="ŞİRKETLERİMİZ" />
    <div class="companies-page">
        <div class="web-center">
            <div class="companies-page-container">
                <div class="companies-info-box">
                    <div class="info-box-left">
                        <img img src="@/assets/img/cihantek-orj.png" alt="">
                    </div>
                    <div class="info-box-right">
                        <div class="info-box-top">
                            <p>1982 yılında ticari hayatına başlayan ''Cihan Elektronik Paz. İnş. San. Tic. Ltd. Şti.''; Elektronik pazarlama, mağazacılık, satış sonrası hizmetler ve GSM alanlarında çeşitli faaliyetlerde bulunmaktadır.  </p>
                        </div>
                        <div class="info-box-bottom">
                            <div class="info-box-website">
                                <a target="_blank" href="http://www.cihanelektronik.net/">www.cihanelektronik.com.tr</a>
                            </div>
                            <div class="info-box-social">
                                <a target="_blank" href="https://www.facebook.com/profile.php?id=100063868963101"><i class="fa-brands fa-facebook-f"></i></a>
                                <a target="_blank" href="https://www.instagram.com/cihantekiletisim/"><i class="fa-brands fa-instagram"></i></a>
                                <a target="_blank" href="https://tr.linkedin.com/company/cihan-elektronik"><i class="fa-brands fa-linkedin-in"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="companies-info-box">
                    <div class="info-box-left">
                        <img img src="@/assets/img/ceptas-orj.avif" alt="">
                    </div>
                    <div class="info-box-right">
                        <div class="info-box-top">
                            <p>1982 yılında ticari hayatına başlayan ‘’Cihan Elektronik’’ ; Elektronik pazarlama, mağazacılık, satış sonrası hizmetler ve GSM alanında çeşitli faaliyetlerde bulunmaktadır.<br><br>
                                Geçen zaman içerisinde; Ceptaş A.Ş. ve KÇM (Kurumsal Çözüm Merkezi) İletişim Hizmetleri Ltd. Şti.lerini kurarak, faaliyetlerini bu 3 ayrı grup şirketi bünyesinde toplamıştır. Rize, Artvin, Ardahan ve Samsun illerinde sekiz Turkcell İletişim Merkezi, Beyaz Eşya Mağazacılık, Mobilya Mağazacılık, Elektronik pazarlama ve İnşaat alanlarında faaliyet göstermektedir.</p>
                        </div>
                        <div class="info-box-bottom">
                            <div class="info-box-website">
                                <a target="_blank" href="https://ceptas.com.tr/">www.ceptas.com.tr</a>
                            </div>
                            <div class="info-box-social">
                                <a target="_blank" href="https://www.facebook.com/ceptastdm"><i class="fa-brands fa-facebook-f"></i></a>
                                <a target="_blank" href="https://www.instagram.com/ceptasiletisimhizmetleri"><i class="fa-brands fa-instagram"></i></a>
                                <a target="_blank" href="https://tr.linkedin.com/company/ceptas-i̇letisim"><i class="fa-brands fa-linkedin-in"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="companies-info-box">
                    <div class="info-box-left">
                        <img img src="@/assets/img/mobiyaz-orj.png" alt="">
                    </div>
                    <div class="info-box-right">
                        <div class="info-box-top">
                            <p>1982 yılında ticari hayatına başlayan grup şirketlerin parçası olan “Mobiyaz Mağazacılık" , mobilya
                                mağazacılığı, mobilya pazarlama, satış sonrası hizmetler ve çeşitli alanlarda faaliyetlerde
                                bulunmaktadır. <br><br>
                                Mobiyaz Mağazacılık ve Bilişim Teknolojileri A.Ş. ile Samsun, Amasya illerinde  7 ayrı noktada
                                hizmet veren Kelebek, Doğtaş ve Lova Yatak Mağazalarıyla faaliyet göstermektedir.
                                </p>
                        </div>
                        <div class="info-box-bottom">
                            <div class="info-box-website">
                                <a href="https://mobiyaz.com.tr/">www.mobiyaz.com.tr</a>
                            </div>
                            <!--
                            <div class="info-box-social">
                                <a target="_blank" href="https://www.facebook.com/cepsofttr"><i class="fa-brands fa-facebook-f"></i></a>
                                <a target="_blank" href="https://www.instagram.com/cepsofttr"><i class="fa-brands fa-instagram"></i></a>
                                <a target="_blank" href="https://www.linkedin.com/company/cepsoft-bili%C5%9Fim-teknolojileri-a-%C5%9F"><i class="fa-brands fa-linkedin-in"></i></a>
                            </div>
                        -->
                        </div>
                    </div>
                </div>
                <div class="companies-info-box">
                    <div class="info-box-left">
                        <img img src="@/assets/img/cepsoft-orj.png" alt="">
                    </div>
                    <div class="info-box-right">
                        <div class="info-box-top">
                            <p>
                                Cepsoft şirketler grubumuzun en genç üyesi olarak 2020 Eylül’de kurulmuştur. ‘’Kolaylaştırır ve kazandırır’’ ilkesiyle yola çıkmış olan Cepsoft, sektörel yazılımlar, dijital / sosyal medya, e-ticaret alanında satış ve pazarlama hizmetleri sunmaktadır.
                            </p>
                        </div>
                        <div class="info-box-bottom">
                            <div class="info-box-website">
                                <a target="_blank" href="https://cepsoft.com.tr/">www.cepsoft.com.tr</a>
                            </div>
                            <div class="info-box-social">
                                <a target="_blank" href="https://www.facebook.com/cepsofttr"><i class="fa-brands fa-facebook-f"></i></a>
                                <a target="_blank" href="https://www.instagram.com/cepsofttr"><i class="fa-brands fa-instagram"></i></a>
                                <a target="_blank" href="https://www.linkedin.com/company/cepsoft-bilişim-teknolojileri-a-ş"><i class="fa-brands fa-linkedin-in"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import PageTitle from '@/components/PageTitle.vue'

    export default {
    name: 'Sirketlerimiz',
    components: {
        PageTitle
    },
    }
</script>

<style scoped>


</style>