<template>
   <PageTitle title="FAALİYET ALANLARI" />
    <div class="faaliyet">
        <div class="web-center">
            <div class="faaliyet-header">
                <h3>
                Mobilya, enerji, inşaat ve perakende sektörlerinde yer alan 9 markamız ile doğa dostu bir üretim anlayışını esas alarak gelişmeye ve üretmeye devam ediyoruz. İçinde bulunduğumuz her sektörde değerlerimizden ve kaliteden ödün vermeden dünyanın hemen her yerinde büyümeyi hedefliyoruz.
                </h3>
            </div>
            <div class="faaliyet-container">
                <div :style="imageElektronik" class="faaliyet-box">
                    <h3>ELEKTRONİK</h3>
                </div>
                <div :style="imagePerakende" class="faaliyet-box faaliyet-box-up">
                    <h3>PERAKENDE</h3>
                </div>
                <div :style="imageMobilya" class="faaliyet-box">
                    <h3>MOBİLYA</h3>
                </div>
                <div :style="imageBilisim" class="faaliyet-box faaliyet-box-up">
                    <h3>BİLİŞİM</h3>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import PageTitle from '@/components/PageTitle.vue'


    export default {
    name: 'FaaliyetAlanlari',
    components: {
        PageTitle
    },
    data() {
      return {
        imageElektronik: {backgroundImage: `url(${require('@/assets/img/elektronik-bg.webp')})`,backgroundSize: "cover"},
        imagePerakende: {backgroundImage: `url(${require('@/assets/img/perakende-bg.webp')})`,backgroundSize: "cover"},
        imageMobilya: {backgroundImage: `url(${require('@/assets/img/mobilya-bg.webp')})`,backgroundSize: "cover"},
        imageBilisim: {backgroundImage: `url(${require('@/assets/img/bilisim-bg.webp')})`,backgroundSize: "cover"}
      }
    }
    }
    
</script>

<style scoped>


</style>