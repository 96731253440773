<template>
  <AnasayfaBody/>
</template>

<script>
// @ is an alias to /src
import AnasayfaBody from '@/components/AnasayfaBody.vue'

export default {
  name: 'Anasayfa',
  components: {
    AnasayfaBody
  }
}
</script>
