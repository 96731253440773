<template>
    <video class="slider-video" autoplay muted loop>
        <source src="@/assets/img/bgvideo.mp4" type="video/mp4">
    </video>
    <div class="companies flex-center">
        <div class="web-center">
            <div class="companies-container">
                <a target="_blank" href="http://www.cihanelektronik.net/">
                    <div class="company-box">
                        <img src="@/assets/img/cihantek.png">
                    </div>
                </a>
                <a target="_blank" href="https://ceptas.com.tr/">
                    <div class="company-box">
                        <img src="@/assets/img/ceptas.png">
                    </div>
                </a>
                <a href="javascript:void(0)">
                    <div class="company-box">
                        <img src="@/assets/img/mobiyaz.png">
                    </div>
                </a>
                <a target="_blank" href="http://www.cepsoft.com.tr/">
                    <div class="company-box">
                        <img src="@/assets/img/cepsoft.png">
                    </div>
                </a>
            </div>
        </div>
    </div>
    <div class="companies m-companies flex-center">
        <div class="web-center">
            <div class="companies-container">
                <a target="_blank" href="http://www.cihanelektronik.net/">
                    <div class="company-box">
                        <img src="@/assets/img/cihantek-orj.png">
                    </div>
                </a>
                <a target="_blank" href="https://ceptas.com.tr/">
                    <div class="company-box">
                        <img src="@/assets/img/ceptas-orj.avif">
                    </div>
                </a>
                <a href="javascript:void(0)">
                    <div class="company-box">
                        <img src="@/assets/img/mobiyaz-orj.png">
                    </div>
                </a>
                <a target="_blank" href="http://www.cepsoft.com.tr/">
                    <div class="company-box">
                        <img src="@/assets/img/cepsoft-orj.png">
                    </div>
                </a>
            </div>
        </div>
    </div>
</template>

<script>

export default {
  name: 'AnasayfaBody'
}
</script>

<style scoped>

</style>
