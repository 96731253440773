<template>
    <PageTitle title="İLETİŞİM" />
   <div class="contact">
    <div class="web-center">
        <div class="contact-container">
            <div class="contact-company-logo">
                <img img src="@/assets/img/cihantek-orj.png" alt="">
            </div>
            <div class="contact-info">
                <div class="contact-left">
                    <div class="contact-box">
                        <span class="contact-icon-box"><i class="fa-solid fa-location-dot"></i></span>
                        <span class="contact-text">Merkez: Portakallık Mah. Menderes Bulvarı No:392 53100 Merkez / RİZE</span>
                    </div>
                    <div class="contact-box">
                        <span class="contact-icon-box"><i class="fa-solid fa-phone"></i></span>
                        <a class="contact-text" href="tel:04642144023"><span >0464 214 40 23</span></a>
                    </div>
                    <div class="contact-box">
                        <span class="contact-icon-box"><i class="fa-solid fa-print"></i></span>
                        <a class="contact-text" href="tel:04642147858"><span >0464 214 78 58</span></a>
                    </div>
                    <div class="contact-box">
                        <span class="contact-icon-box"><i class="fa-solid fa-envelope"></i></span>
                        <a class="contact-text" href="mailto:info@cihanelektronik.net"><span >info@cihanelektronik.net</span></a>
                    </div>
                    <div class="contact-box">
                        <span class="contact-icon-box"><i class="fa-solid fa-globe"></i></span>
                        <a target="_blank" class="contact-text" href="http://www.cihanelektronik.net/"><span>http://www.cihanelektronik.net</span></a>
                    </div>
                </div>
                <div class="contact-maps">
                    <iframe class="contact-iframe" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d24077.957259846473!2d40.55344!3d41.030842!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40667ab6f7580103%3A0x951816214abf1f0a!2sBa%C4%9Fdatl%C4%B1%2C%20Menderes%20Blv.%20No%3A392%2C%2053020%20Rize%20Merkez%2FRize!5e0!3m2!1str!2str!4v1664442663306!5m2!1str!2str" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
        </div>
        <div class="contact-container">
            <div class="contact-company-logo">
                <img img src="@/assets/img/ceptas-orj.avif" alt="">
            </div>
            <div class="contact-info">
                <div class="contact-left">
                    <div class="contact-box">
                        <span class="contact-icon-box"><i class="fa-solid fa-location-dot"></i></span>
                        <span class="contact-text">Yenimahalle Atatürk Bulvarı 3. Kısım No:43 Platin Ofis Kat:4 Atakum / SAMSUN</span>
                    </div>
                    <div class="contact-box">
                        <span class="contact-icon-box"><i class="fa-solid fa-phone"></i></span>
                        <a class="contact-text" href="tel:03624393000"><span >0 362 439 30 00</span></a>
                    </div>
                    <div class="contact-box">
                        <span class="contact-icon-box"><i class="fa-solid fa-print"></i></span>
                        <a class="contact-text" href="tel:04642178874"><span >0464 217 88 74</span></a>
                    </div>
                    <div class="contact-box">
                        <span class="contact-icon-box"><i class="fa-solid fa-envelope"></i></span>
                        <a class="contact-text" href="mailto:info@ceptas.com.tr"><span >info@ceptas.com.tr</span></a>
                    </div>
                    <div class="contact-box">
                        <span class="contact-icon-box"><i class="fa-solid fa-globe"></i></span>
                        <a target="_blank" class="contact-text" href="https://ceptas.com.tr/"><span>www.ceptas.com.tr</span></a>
                    </div>
                </div>
                <div class="contact-maps">
                    <iframe class="contact-iframe" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d374.42254882455507!2d36.249688!3d41.344085!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x3ba71f0e30a181e7!2zQ0VQVEHFniDEsExFVMSwxZ7EsE0gSMSwWk1FVExFUsSwIEHFng!5e0!3m2!1str!2str!4v1664443080712!5m2!1str!2str" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
        </div>
        <div class="contact-container">
            <div class="contact-company-logo">
                <img img src="@/assets/img/mobiyaz-orj.png" alt="">
            </div>
            <div class="contact-info">
                <div class="contact-left">
                    <div class="contact-box">
                        <span class="contact-icon-box"><i class="fa-solid fa-location-dot"></i></span>
                        <span class="contact-text">Kirazlık Mah. Atatürk Bulvarı. No:51 Tekkeköy / SAMSUN</span>
                    </div>
                    <div class="contact-box">
                        <span class="contact-icon-box"><i class="fa-solid fa-phone"></i></span>
                        <a class="contact-text" href="tel:03622666070"><span >0 362 266 6070</span></a>
                    </div>
                    <div class="contact-box">
                        <span class="contact-icon-box"><i class="fa-solid fa-envelope"></i></span>
                        <a class="contact-text" href="mailto:info@mobiyaz.com.tr"><span >info@mobiyaz.com.tr</span></a>
                    </div>
                    <div class="contact-box">
                        <span class="contact-icon-box"><i class="fa-solid fa-globe"></i></span>
                        <a class="contact-text" href="js:"><span>www.mobiyaz.com.tr</span></a>
                    </div>
                </div>
                <div class="contact-maps">
                    <iframe class="contact-iframe" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d187.48708915229938!2d36.3956513741844!3d41.248057442108326!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x408875f56dbd2ba7%3A0x5b86de42330f926a!2sDo%C4%9Fta%C5%9F%20Exclusive%20Samsun%20Merkez!5e0!3m2!1str!2str!4v1664443982752!5m2!1str!2str" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
        </div>
        <div class="contact-container">
            <div class="contact-company-logo">
                <img img src="@/assets/img/cepsoft-orj.png" alt="">
            </div>
            <div class="contact-info">
                <div class="contact-left">
                    <div class="contact-box">
                        <span class="contact-icon-box"><i class="fa-solid fa-location-dot"></i></span>
                        <span class="contact-text">Yenimahalle Atatürk 3. Kısım Bulvarı Platin Ofis No:43/9 Kat: 10 Atakum / SAMSUN</span>
                    </div>
                    <div class="contact-box">
                        <span class="contact-icon-box"><i class="fa-solid fa-phone"></i></span>
                        <a class="contact-text" href="tel:08503059900"><span >0850 305 99 00</span></a>
                    </div>
                    <div class="contact-box">
                        <span class="contact-icon-box"><i class="fa-solid fa-envelope"></i></span>
                        <a class="contact-text" href="mailto:info@cepsoft.com.tr"><span >info@cepsoft.com.tr</span></a>
                    </div>
                    <div class="contact-box">
                        <span class="contact-icon-box"><i class="fa-solid fa-globe"></i></span>
                        <a target="_blank" class="contact-text" href="https://cepsoft.com.tr/"><span>www.cepsoft.com.tr</span></a>
                    </div>
                </div>
                <div class="contact-maps">
                    <iframe class="contact-iframe" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11981.498249676768!2d36.2498403!3d41.3442117!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x901c80c9e506de95!2sCepsoft%20Bili%C5%9Fim%20Teknolojileri%20A.%C5%9E!5e0!3m2!1str!2str!4v1664440077090!5m2!1str!2str" width="500" height="300px" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
        </div>
    </div>
   </div>
</template>

<script>

import PageTitle from '@/components/PageTitle.vue'


    export default {
    name: 'Contact',
    components: {
        PageTitle
    },
    }
    
</script>

<style scoped>


</style>