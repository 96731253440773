<template>
    <div class="footer">
        <div class="footer-header">
            <div class="web-center">
            <div class="footer-top">
                    <div class="footer-logo">
                        <img src="@/assets/img/ceptasgrup.png" alt="">
                    </div>
                    <div class="footer-menu">
                        <ul>
                            <li>
                                <router-link to="/hakkimizda">Hakkımızda</router-link>
                            </li>
                            <li>
                                <router-link to="/faaliyetalanlari">Faaliyet Alanlarımız</router-link>
                            </li>
                            <li>
                                <router-link to="/sirketlerimiz">Şirketlerimiz</router-link>
                            </li>
                            <li>
                                <router-link to="/iletisim">İletişim</router-link>
                            </li>
                        </ul>
                    </div>
                    <div class="footer-contact">
                        <div class="footer-adress">
                            <p>
                            Yenimahalle Atatürk Bulvarı<br> 3. Kısım No:43 Platin Ofis Kat:4<br> Atakum / SAMSUN
                            </p>
                        </div>
                        <div class="footer-number-fax-mail">
                            <span>0 362 439 30 00</span>
                            <span>info@ceptasgrup.com.tr</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-down">
            <div class="web-center">
            <div class="footer-bottom">
                    <div class="footer-name">
                        <h6>&reg; Ceptaş Grup - 2022</h6>
                    </div>
                    <div class="footer-social">
                        <a href="js:"><i class="fa-brands fa-linkedin-in"></i></a>
                        <a href="js:"><i class="fa-brands fa-facebook-f"></i></a>
                        <a href="js:"><i class="fa-brands fa-instagram"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
name: 'Footer'
}

</script>

<style scoped>
</style>
    