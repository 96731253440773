import { createRouter, createWebHistory } from 'vue-router'
import Anasayfa from '../views/Anasayfa.vue'
import AnasayfaBody from '../components/AnasayfaBody.vue'
import FaaliyetAlanlari from '../views/FaaliyetAlanlari.vue'
import Contact from '../views/Contact.vue'
import Hakkimizda from '../views/Hakkimizda.vue'
import Sirketlerimiz from '../views/Sirketlerimiz.vue'

const routes = [
  {
    path: '/',
    name: 'Anasayfa',
    component: AnasayfaBody,
    meta:{
      title : 'CEPTAS GRUP  Perakende | Toptan | Mobilya | Bilişim | CEPTAS GRUP Şirketler Grubu'
    }
  },
  {
    path: '/hakkimizda',
    name: 'Hakkimizda',
    component: Hakkimizda,
    meta:{
      title : 'Hakkımızda'
    }
  },
  {
    path: '/faaliyetalanlari',
    name: 'FaaliyetAlanlari',
    component: FaaliyetAlanlari ,
    meta:{
      title : 'Faaliyet Alanları'
    }
  },
  {
    path: '/sirketlerimiz',
    name: 'Sirketlerimiz',
    component: Sirketlerimiz ,
    meta:{
      title : 'Şirketlerimiz'
    }
  },
  {
    path: '/iletisim',
    name: 'Contact',
    component: Contact,
    meta:{
      title : 'İletişim'
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: AnasayfaBody,
    meta:{
      title : 'CEPTAS GRUP  Perakende | Toptan | Mobilya | Bilişim | CEPTAS GRUP Şirketler Grubu'
    }
  },
]

const router = createRouter({
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 }
  },
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from , next) => {
    document.title = `${to.meta.title+' | Ceptaş Grup'}`;
    next();
});

export default router
