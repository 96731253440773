<template>
    <PageTitle title="HAKKIMIZDA" />
   <div class="about">
    <div class="web-center">
        <div class="about-container">
            <div class="about-text">
                <p>
                    1982 yılında Artvin’de <b>Cihan Yazıcı</b> tarafından kurulan Cihan Elektronik işletmesine dayanan
                    şirketler grubumuz 1995’te Cihan Elektronik Ltd. Şti. tüzel kişiliğiyle faaliyetlerine devam etmiştir.
                    Çeşitli Elektronik ve Teknoloji ürünlerinin toptan satış ve pazarlama faaliyetlerini yürüten Cihan
                    Elektronik, 1999 yılında Turkcell ile iş ortaklığına başlamasının ardından bölgesinde şubeleşmeye
                    gitmiştir. 2021 yılı başında Cihan Elektronik Ltd. Şti. olan şirketimiz unvan değiştirerek Cihantek
                    İletişim ve Teknoloji Hizmetleri Anonim Şirketi olarak tescillenmiştir.
                </p>
                <p>
                    2008 yılında Turkcell’in perakende ve toptan kanallarını ayırma stratejisi kapsamında tarafımıza
                    sunulan yatırım fırsatı sonrası Ceptaş A.Ş.'yi kurarak Turkcell Dağıtım Merkezlerini faaliyete geçirdik.
                    Bugün itibariyle, Orta Karadeniz, Doğu Karadeniz ve İç Anadolu bölgelerindeki 16 ilden sorumlu
                    olarak Turkcell’in 8 Dağıtım Merkezinden biri olarak faaliyetlerimizi sürdürmekteyiz.
                </p>
                <p>
                    2015 yılında Doğtaş markasıyla mobilya sektörüne yatırım yaptık ve Mobilya Perakendecisi olarak
                    yeni bir iş koluna girmiş olduk. MOBİYAZ Mağazacılık A.Ş.‘ni kurarak mobilya faaliyetlerimizi bu
                    şirket çatısı altına topladık.
                    2020 yılı sonunda, e-ticaret alanında faaliyette bulunmak amacıyla, 4. Grup şirketi olarak Cepsoft
                    Bilişim Teknolojileri A.Ş.’yi kurduk.<br><br>
                    2016 yılında <b>Orhan Yazıcı</b>’nın Cihan Elektronik şirketimizde Genel Koordinatör olarak görev almaya
                    başlaması ile birlikte Yazıcı ailesinde 2. Kuşak yönetici dönemi başlamış oldu.
                    <b>Orhan Yazıcı</b> 2019 yılından sonra Ceptaş A.Ş.’de Genel Koordinatör olarak görev yaptı ve bu
                    dönemler grup şirketlerimiz açısından oldukça başarılı işlere imza attı. <b>Orhan Yazıcı</b> 01.01.2021
                    tarihi itibariyle Grup Şirketlerinin Genel Müdürü olarak tüm icra faaliyetlerinin başında yer
                    almaktadır.
                </p>
            </div>
            <div class="about-image">
                <img src="@/assets/img/about-image.webp" alt="">
            </div>
        </div>
        <div class="about-mission-vision">
            <div class="mission-vision-box">
                <div class="mission-vision-box-top">
                    <h3>MİSYON</h3>
                    <img src="@/assets/img/quetos.svg" alt="">
                </div>
                <div class="mission-vision-box-bottom">
                    <h6>Dokunduğumuz herkesin hayatına değer katmak ve üstlendiğimiz her sorumluluğu fark yaratarak gerçekleştirmek.</h6>
                </div>
            </div>
            <div class="mission-vision-box">
                <div class="mission-vision-box-top">
                    <h3>VİZYON</h3>
                    <img src="@/assets/img/quetos.svg" alt="">
                </div>
                <div class="mission-vision-box-bottom">
                    <h6>Sektöründe geleceğe yön veren ulusal bir marka olmak.</h6>
                </div>
            </div>
        </div>
    </div>
   </div>
</template>

<script>

import PageTitle from '@/components/PageTitle.vue'

    export default {
    name: 'Hakkimizda',
    components: {
        PageTitle
    },
    }
</script>

<style scoped>


</style>